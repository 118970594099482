import './characterSelect2.css';

import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { CHRCT_SELECT } from '../../inworld';
import { save as saveConfiguration } from '../helpers/configuration';
import { ConfigurationSession } from '../types';

const getVisitCounts = async () => {
  const response = await axios.get('https://saynow.online/api/visit-counts');
  return response.data;
};

const incrementVisitCount = async (character) => {
  await axios.post('https://saynow.online/api/increment-visit', { character });
};

export const CharacterSelect2 = () => {
  const { control, getValues, setValue } =
    useFormContext<ConfigurationSession>();
  const [visitCounts, setVisitCounts] = useState({});
  const [lastClickedCharacter, setLastClickedCharacter] = useState(null);
  const [goClicked, setGoClicked] = useState(false);

  const handleCharacterClick = (character) => {
    setLastClickedCharacter(character);
    setGoClicked(false);
  };

  const handleSelectionChange = (option) => {
    setValue('characterSelect', option);
    saveConfiguration(getValues());
  };

  const handleGoButtonClick = async () => {
    if (lastClickedCharacter && !goClicked) {
      await incrementVisitCount(lastClickedCharacter);
      const updatedCounts = await getVisitCounts();
      setVisitCounts(updatedCounts);
      setGoClicked(true);
    }
  };

  useEffect(() => {
    const fetchVisitCounts = async () => {
      const counts = await getVisitCounts();
      setVisitCounts(counts);
    };
    fetchVisitCounts();
  }, []);

  useEffect(() => {
    const goButton = document.getElementById('goButton');
    if (goButton) {
      goButton.addEventListener('click', handleGoButtonClick);
    }
    return () => {
      if (goButton) {
        goButton.removeEventListener('click', handleGoButtonClick);
      }
    };
  }, [lastClickedCharacter]);

  return (
    <Box sx={{ m: 2, fontSize: 10 }}>
      {Object.values(CHRCT_SELECT).map((option) => (
        <FormControl key={option}>
          <RadioGroup row>
            <FormControlLabel
              value={option}
              control={
                <Controller
                  name="characterSelect"
                  control={control}
                  render={({ field: { value } }) => (
                    <Radio
                      size="medium"
                      checked={(value as unknown as CHRCT_SELECT) === option}
                      onChange={() => handleSelectionChange(option)}
                      onClick={() => handleCharacterClick(option)}
                    />
                  )}
                />
              }
              label={
                <div className="characterHeader">
                  <img
                    src={option + '.png'}
                    alt=""
                    style={{ maxWidth: '200px' }}
                  />
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p
                      className="c-name"
                      style={{
                        fontSize: '20px',
                        fontWeight: 'bold',
                        marginRight: '10px',
                      }}
                    >
                      {option}
                    </p>
                    <div
                      className="visitCount"
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <VisibilityIcon />
                      <span>{visitCounts[option] || 0}</span>
                    </div>
                  </div>
                </div>
              }
            />
          </RadioGroup>
        </FormControl>
      ))}
    </Box>
  );
};
