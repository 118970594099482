import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';

import { CHAT_VIEW } from '../types';
/* import { Audio } from './Audio';
import { CharacterName } from './CharacterName';
import { CharacterSelect } from './CharacterSelect';
import { ChatView } from './ChatView';
import { SceneName } from './SceneName'; */
import { CharacterSelect2 } from './CharacterSelect2';
import { PlayerName } from './PlayerName';
interface ConfigViewProps {
  chatView?: CHAT_VIEW;
  canStart: boolean;
  onStart: () => Promise<void>;
  onResetForm: () => void;
}

export const ConfigView = (props: ConfigViewProps) => {
  return (
    <>
      <Box component="form">
        <Typography variant="h7" component="h1" sx={{ m: 1 }}>
          Saynow!
        </Typography>
        <Card sx={{ mb: 2 }}>
          <CardContent>
            {/*             <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <CharacterName />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SceneName />
              </Grid>
            </Grid> */}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <CharacterSelect2 />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <PlayerName />
              </Grid>
              {/*               <Grid item xs={12} sm={6}>
                <ChatView />
              </Grid> */}
            </Grid>

            {/*             <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <CharacterSelect />
              </Grid>
            </Grid> */}
            {/*             {props.chatView === CHAT_VIEW.TEXT && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Audio />
                </Grid>
              </Grid>
            )} */}
          </CardContent>
        </Card>
      </Box>
      <Grid
        container
        mt={1}
        spacing={2}
        alignItems="center"
        justifyContent={'flex-end'}
      >
        <Grid item>
          {/*           <Button
            sx={{ mr: 2 }}
            variant="contained"
            onClick={props.onResetForm}
          >
            Reset
          </Button> */}
          <Button
            id="goButton"
            variant="contained"
            disabled={!props.canStart}
            onClick={props.onStart}
          >
            Go!
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
