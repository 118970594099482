export enum CHRCT_SELECT {
  PERSON1 = 'Words Guessing',
  PERSON2 = '诗人苏轼',
  PERSON3 = 'Kakashi(homemade)',
  PERSON4 = 'Elf',
  PERSON5 = 'IELTS Teacher',
  PERSON6 = 'Dog Teacher',
  PERSON7 = 'XCreator',
  PERSON8 = 'AI Guo',
  PERSON9 = 'Kobe Bryant',
  PERSON10 = 'lilt jay',
  PERSON11 = 'Stephen curry',
  PERSON12 = 'Bond',
  PERSON13 = 'The White Rabbit',
  PERSON14 = 'Global reporter Elena',
  PERSON15 = 'Basketball Prodigy Ace',
  PERSON16 = 'History Prof. Alex',
  PERSON17 = 'Musician Luna',
  PERSON18 = 'Politics Dr.Benjamin',
  PERSON19 = 'Sci-tech Journalist Oliver',
  PERSON20 = 'Artist Leon',
  PERSON21 = 'Chef Terrance',
  PERSON22 = 'Designer Dahlia',
  PERSON23 = 'Software Engineer Max',
  PERSON24 = 'Student Ethan',
}

type CharacterProps = {
  id: CHRCT_SELECT;
  name: string;
  scene: string;
  author: string;
  pageview: string;
};

export const characterMap: CharacterProps[] = [
  {
    id: CHRCT_SELECT.PERSON1,
    name: 'workspaces/saynow/characters/wggg__word_guessing_game_guy',
    scene: 'workspaces/saynow/scenes/saynow',
    author: 'kakashi',
  },
  {
    id: CHRCT_SELECT.PERSON2,
    name: 'workspaces/saynow/characters/sushi_poet',
    scene: 'workspaces/saynow/scenes/saynow',
    author: 'kakashi',
  },
  {
    id: CHRCT_SELECT.PERSON3,
    name: 'workspaces/saynow/characters/kakashihomemade',
    scene: 'workspaces/saynow/scenes/saynow',
    author: 'japser',
  },
  {
    id: CHRCT_SELECT.PERSON4,
    name: 'workspaces/saynow/characters/elf',
    scene: 'workspaces/saynow/scenes/saynow',
    author: 'kakashi',
  },
  {
    id: CHRCT_SELECT.PERSON5,
    name: 'workspaces/saynow/characters/ielts_english_teacher',
    scene: 'workspaces/saynow/scenes/saynow',
    author: 'kakashi',
  },
  {
    id: CHRCT_SELECT.PERSON6,
    name: 'workspaces/saynow/characters/dog_teacher-izfpj',
    scene: 'workspaces/saynow/scenes/saynow',
    author: 'kakashi',
  },
  {
    id: CHRCT_SELECT.PERSON7,
    name: 'workspaces/saynow/characters/lander',
    scene: 'workspaces/saynow/scenes/saynow',
    author: 'japser',
  },
  {
    id: CHRCT_SELECT.PERSON8,
    name: 'workspaces/saynow/characters/ai_guo_',
    scene: 'workspaces/saynow/scenes/saynow',
    author: 'kakashi',
  },
  {
    id: CHRCT_SELECT.PERSON9,
    name: 'workspaces/saynow/characters/kobe_bryant',
    scene: 'workspaces/saynow/scenes/saynow',
    author: '胡梓昕',
  },
  {
    id: CHRCT_SELECT.PERSON10,
    name: 'workspaces/saynow/characters/lilt_jay',
    scene: 'workspaces/saynow/scenes/saynow',
    author: 'Ray',
  },
  {
    id: CHRCT_SELECT.PERSON11,
    name: 'workspaces/saynow/characters/stephen_curry_',
    scene: 'workspaces/saynow/scenes/saynow',
    author: 'kakashi',
  },
  {
    id: CHRCT_SELECT.PERSON12,
    name: 'workspaces/saynow/characters/bond',
    scene: 'workspaces/saynow/scenes/saynow',
    author: '郑峻博',
  },
  {
    id: CHRCT_SELECT.PERSON13,
    name: 'workspaces/saynow/characters/the_white_rabbit',
    scene: 'workspaces/saynow/scenes/saynow',
    author: 'kakashi',
  },
  {
    id: CHRCT_SELECT.PERSON14,
    name: 'workspaces/saynow/characters/global_reporter_elena',
    scene: 'workspaces/saynow/scenes/saynow',
    author: 'brian',
  },
  {
    id: CHRCT_SELECT.PERSON15,
    name: 'workspaces/saynow/characters/basketball_prodigy_ace',
    scene: 'workspaces/saynow/scenes/saynow',
    author: 'brian',
  },
  {
    id: CHRCT_SELECT.PERSON16,
    name: 'workspaces/saynow/characters/history_prof__alex',
    scene: 'workspaces/saynow/scenes/saynow',
    author: 'brian',
  },
  {
    id: CHRCT_SELECT.PERSON17,
    name: 'workspaces/saynow/characters/musician_luna',
    scene: 'workspaces/saynow/scenes/saynow',
    author: 'brian',
  },
  {
    id: CHRCT_SELECT.PERSON18,
    name: 'workspaces/saynow/characters/politics_drbenjamin',
    scene: 'workspaces/saynow/scenes/saynow',
    author: 'brian',
  },
  {
    id: CHRCT_SELECT.PERSON19,
    name: 'workspaces/saynow/characters/sci-tech_journalist_oliver',
    scene: 'workspaces/saynow/scenes/saynow',
    author: 'brian',
  },
  {
    id: CHRCT_SELECT.PERSON20,
    name: 'workspaces/saynow/characters/artist_leon',
    scene: 'workspaces/saynow/scenes/saynow',
    author: 'brian',
  },
  {
    id: CHRCT_SELECT.PERSON21,
    name: 'workspaces/saynow/characters/chef_terrance',
    scene: 'workspaces/saynow/scenes/saynow',
    author: 'brian',
  },
  {
    id: CHRCT_SELECT.PERSON22,
    name: 'workspaces/saynow/characters/designer_dahlia',
    scene: 'workspaces/saynow/scenes/saynow',
    author: 'brian',
  },
  {
    id: CHRCT_SELECT.PERSON23,
    name: 'workspaces/saynow/characters/software_engineer_max',
    scene: 'workspaces/saynow/scenes/saynow',
    author: 'brian',
  },
  {
    id: CHRCT_SELECT.PERSON24,
    name: 'workspaces/saynow/characters/ethan',
    scene: 'workspaces/saynow/scenes/saynow',
    author: 'brian',
  },
];
